import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";

const PendingOrders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const pendingOrdersList = useSelector((state) => state?.order?.ordersList);

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "pendingOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
        if (res.status === 401) {
            toast.error(`${res?.message}`, { className: "toast-message"});
            localStorage.removeItem("user");
            navigate("/");
        }
    });
    document.title = "Pending Orders | Philippines Admin Portal";
    }, [dispatch, navigate, page, limit]);

    const tableData = pendingOrdersList;

    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Pending Orders</h1>
            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                <div className="table-block table-responsive">
                    <ReactDataTable
                    data={tableData}
                    setPage={setPage}
                    setLimit={setLimit}
                    orderName="pendingOrder"
                    />
                </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default PendingOrders;
