import React from "react";
import { StyledFooter } from "./style";

const Footer = () => {
  return (
    <StyledFooter>
      <div className="container py-12" id="footer">
        <div className="me-md-auto text-center">
          <div className="copyright">Philippines © 2024. ALL RIGHTS RESERVED. </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
