import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utils/utility";
import AdminLogo from "../../../assets/images/logo.png";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  console.log("OrderDetails", OrderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          style={{ padding: "-37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p style={{ fontSize: "16px" }}>
        <b>{`Dear Customer: ${OrderDetails?.first_name || ''} ${OrderDetails?.middle_name || ''} ${OrderDetails?.last_name || ''}`}</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        We would like to inform you that your e-Travel application has been
        successfully approved.
      </p>
      {/* <p style={{ fontSize: "16px" }}>
        <b>An ECD is automatically linked to your passport.</b>
      </p> */}
      <p style={{ fontSize: "16px" }}>To download your e-Travel Document confirmation:</p>
      <p style={{ fontSize: "16px" }}>Please click on the link below</p>
      <p style={{ fontSize: "16px" }}>
        Username:{" "}
        <a href={`mailto:${OrderDetails?.user?.email || ''}`}>
          {OrderDetails?.user?.email || ''}
        </a>
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_number}</p>
      <br />
      <Link target="__blank" to={`https://admin-ph.online/track-order/${encryptVal(OrderDetails?.order_number)}`} className="apply-btn">
        Click Here
      </Link>
      <br />
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>Reminder:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        It is recommended, but not required, that you take a printed or digital
        copy of your e-Travel Document with you.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please make sure you check the official requirements and health
        regulations before traveling to your destination as travel restrictions
        may be applied in some cases.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please contact us with any questions you may have regarding your e-Travel Document.
      </p>
      <p style={{ fontSize: "16px" }}>
        Should you have any inquiries, please contact us through our{" "}
        <Link to="https://philippines-etd.com/contact-us">contact form</Link> or
        via email at{" "}
        <a href="mailto:inquiries@philippines-etd.com">
          inquiries@philippines-etd.com
        </a>{" "}
        indicating your order ID.
      </p>
      <p style={{ fontSize: "16px" }}>
        <b>Customer Service Number:</b> + 1 (786) 9815 460
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_number}</p>
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>Important Notice:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at{" "}
        <a href="mailto:inquiries@philippines-etd.com">
          inquiries@philippines-etd.com
        </a>
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p style={{ fontSize: "16px" }}>Thank you for using our services.</p>
      <p style={{ fontSize: "16px" }}>Regards,</p>
      <p style={{ fontSize: "16px" }}>
        <b>Processing Department</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        <Link to="https://philippines-etd.com/terms_conditions">
          Terms
        </Link>{" "}
        |{" "}
        <Link to="https://philippines-etd.com/privacy-policy">Privacy</Link> |{" "}
        <Link to="https://philippines-etd.com/contact-us">Contact</Link> |{" "}
        <Link to="https://philippines-etd.com/refund-policy">Refund</Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
