import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock, SearchField } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCountryOrdersList } from "../../../features/order/orderSlice";
import ReactDataTable from "../../../components/ReactDataTable";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { DebounceInput } from "react-debounce-input";


const CountryOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const countryOrderList = useSelector((state) => state?.order?.countryOrderList);
    const isLoading = useSelector((state) => state?.order?.loading);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dateField, setDateField] = useState({
        fromDate: "",
        toDate: ""
    });

    const [filteredArray, setfilteredArray] =
        useState(countryOrderList);
    
        useEffect(()=> {
            setfilteredArray(countryOrderList);
        },[countryOrderList])

    useEffect(() => {
        dispatch(getCountryOrdersList({})).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Country Orders | Philippines Admin Portal";
    }, [dispatch, navigate, page, limit]);

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: false, // Typically, serial numbers are not sorted
        },
        {
            name: "Country",
            selector: (row) => row?.nationality,
            sortable: false,
        },
        {
            name: "Orders",
            selector: (row) => row?.orderCount,
            sortable: false,
        },
    ];

    const handleSubmit = () => {
        const data = { fromDate: dateField.fromDate, toDate: dateField.toDate }
        setDateField({ fromDate: "", toDate: "" });
        dispatch(getCountryOrdersList(data)).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filterText = e.target.value;
        if (
            countryOrderList &&
            countryOrderList.length > 0 &&
            filteredArray
        ) {
            if (filterText !== "") {
                const filteredResult = countryOrderList
                    ?.filter((item) =>
                        (item?.nationality)
                            .toLowerCase()
                            .includes(filterText.toLocaleLowerCase())
                    )
                    .map((item) => {
                        return {
                            id: item.citizenship_id,
                            nationality: item.nationality,
                            orderCount: item.orderCount,
                        };
                    });
                setfilteredArray(filteredResult);
                // Data = filteredArray;
            } else {
                const countryAllData = countryOrderList?.map((item) => {
                    return {
                        id: item?.citizenship_id ? item?.citizenship_id : "-",
                        nationality: item?.nationality ? item?.nationality : "-",
                        orderCount: item?.orderCount ? item?.orderCount : "-",
                    };
                });
                setfilteredArray(countryAllData);
            }
        }
    };
    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>Country Listing</h1>
                </StyledPageTitle>
                <form>
                    <div className="d-flex">
                        <div className="form-group">
                            <label>
                                From:
                                <input
                                    type="date"
                                    name="fromDate"
                                    id="fromDate"
                                    className="form-control input-class"
                                    value={dateField.fromDate}
                                    onChange={(e) => setDateField({ ...dateField, fromDate: e.target.value })}
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                To:
                                <input
                                    type="date"
                                    name="toDate"
                                    id="toDate"
                                    className="form-control input-class"
                                    value={dateField.toDate}
                                    onChange={(e) => setDateField({ ...dateField, toDate: e.target.value })}
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <button
                                type="button"
                                className="blue-btn"
                                id="download_btn"
                                onClick={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <StyledOrderBlock>
                        <SearchField>
                            <DebounceInput
                                minLength={0}
                                debounceTimeout={500}
                                type="text"
                                id="search_field"
                                name="search_field"
                                className="search-bar form-control"
                                value={searchText}
                                onChange={(e) => handleSearch(e)}
                                placeholder="Search"
                            />
                        </SearchField>
                        <div className="table-block table-responsive">
                            <DataTable
                                className="uspassport-custom-tbl"

                                columns={columns}
                                progressPending={isLoading}

                                data={filteredArray}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="550px"
                                pagination={filteredArray?.length ? true : false}
                                noDataComponent="No records found"
                                // buttonWrapper={null}
                                setPage={setPage}
                                setLimit={setLimit}
                                selectableRows={false}
                            />
                            {/* <ReactDataTable
                                customColumns={columns}
                                countryOrderData={tableData}
                                // buttonWrapper={null}
                                setPage={setPage}
                                setLimit={setLimit}
                                hideSelectableRows={true}
                                orderName="country_order"
                            /> */}
                        </div>
                    </StyledOrderBlock>
                </form>
            </StyledContainer>
        </>
    );
}

export default CountryOrders;