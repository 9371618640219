import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ButtonWrapper } from "../ReactDataTable/style";

const MyModal = ({
    show,
    close,
    children,
    size,
    confirmAlert,
    noEvent,
    yesEvent,
    alertMsg,
    modalHeadTitle,
    showFooter,
    primaryButtonName,
    secondaryButtonName,
    OrderDetails,
}) => {
    const handleClose = () => close(false);
    size = typeof size === "undefined" ? "xl" : size;
    alertMsg =
    typeof alertMsg === "undefined"
      ? "Are you sure you want to delete?"
      : alertMsg;
    return (
        <Modal size={size} show={show} onHide={handleClose}>
        {modalHeadTitle && (
            <Modal.Header closeButton>
            <h5>{modalHeadTitle}</h5>
            </Modal.Header>
        )}
        {confirmAlert ? (
            <Modal.Body>
                <p>{alertMsg}</p>
                <button
                    className="btn btn-secondary m-1 float-end"
                    onClick={() => {
                    noEvent();
                    }}
                >
                    No
                </button>
                <button
                    className="btn btn-danger m-1 float-end"
                    onClick={() => {
                    yesEvent();
                    }}
                >
                    Yes
                </button>
            </Modal.Body>
        ) : (
            <>
            <Modal.Body>{children}</Modal.Body>
            {showFooter && (
                <Modal.Footer>
                <ButtonWrapper>
                    <button className="blue-btn" onClick={handleClose}>
                    {primaryButtonName}
                    </button>
                    {/* {console.log(OrderDetails)} */}
                    <Link
                        className="blue-btn"
                        to={`http://wa.me/${OrderDetails?.phone_number}`}
                        onClick={handleClose}
                        >
                        {secondaryButtonName}
                    </Link>
                </ButtonWrapper>
                </Modal.Footer>
            )}
            </>
        )}
        </Modal>
    );
};

export default MyModal;
