import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLoginLayout from './layouts/AdminLoginLayout';
import AdminLayout from './layouts/AdminLayout/AdminLayout';
import AdminTrackLayout from './layouts/AdminTrackLayout/AdminTrackLayout';
const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/*" element={<AdminTrackLayout />} />
        <Route path="/admin/*" element={<AdminLayout/>} /> 
        <Route path="/" element={<AdminLoginLayout/>} />
      </Routes>
    </>    
  );
}

export default App;
